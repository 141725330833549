import LeaveBalance from "./index";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAlltype } from "../../../slices/leavetype";
import { useEffect } from "react";
import "./_LeaveBalance.scss";
import { durationLeaves } from "../../../utilities/durationLeaves";
import getAccess from "../../../utilities/getAccess";

const LeaveBalanceItem = (props) => {
  const { AllLeaves } = props;
  const { leaveTypes } = useSelector((state) => state?.leaveTypes?.leaveTypes);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.fetchMe);

  useEffect(() => {
    if (
      getAccess(
        user?.permissions,
        user?.permissionGroups?.[0]?.permissions,
        "LEAVE TYPE",
        "LIST"
      )
    ) {
      dispatch(fetchAlltype());
    }
  }, [user]);

  return (
    <div>
      <div className="LeaveBalance__bigcontainer">
        {leaveTypes?.map((item) => (
          <>
            <LeaveBalance
              user={user}
              img={item?.img}
              pathColor={item?.color}
              leaveTypes={item?.name}
              leave={item.name}
              AllLeaves={AllLeaves}
              item={item}
              leaveDays={durationLeaves(AllLeaves, item.name)}
            />
          </>
        ))}
      </div>
    </div>
  );
};

export default LeaveBalanceItem;
